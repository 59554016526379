import { render, staticRenderFns } from "./Edit2.vue?vue&type=template&id=e8f3e830&scoped=true&"
import script from "./Edit2.vue?vue&type=script&lang=js&"
export * from "./Edit2.vue?vue&type=script&lang=js&"
import style0 from "./Edit2.vue?vue&type=style&index=0&id=e8f3e830&lang=scss&scoped=true&"
import style1 from "./Edit2.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8f3e830",
  null
  
)

export default component.exports