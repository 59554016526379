var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-algorithm"},[_c('div',{staticClass:"page-title-row"},[_c('h1',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.management'))+" ")]),_c('div',{staticClass:"bread-crumb"},[_c('router-link',{staticClass:"title-link",attrs:{"to":{ name: 'PageAlgorithmList' }}},[_vm._v(" "+_vm._s(_vm.$t('algorithm.name'))+" ")]),_c('span',{staticClass:"divider"},[_vm._v("/")]),_vm._v("{{ $t('algorithm.contourPrediction') ")],1)]),_c('div',{staticClass:"table-board"},[_c('div',{staticClass:"board-title-row"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.trainDataSetting'))+" ")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"item-wrap"},[_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title must"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.targetDataSource'))+": ")]),_c('default-select',{staticClass:"tag-select input",attrs:{"value":1,"option-list":[
              {value: 1, name: '富士康demo'} ]}})],1),_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title must"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.targetDataFrame'))+": ")]),_c('default-select',{staticClass:"tag-select input",attrs:{"value":1,"option-list":[
              { value: 1, name: 'TemperatureData' },
              { value: 2, name: 'molding' } ]}})],1)]),_c('div',{staticClass:"item-wrap"},[_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title must"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.targetParamColumn'))+": ")]),_c('default-select',{staticClass:"tag-select input",attrs:{"value":1,"option-list":[
              { value: 1, name: _vm.$t('algorithm.actualLabel') } ]}})],1),_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title must"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.timeColumn'))+": ")]),_c('default-select',{staticClass:"tag-select input",attrs:{"value":1,"option-list":[
              { value: 1, name: _vm.$t('algorithm.timestamp') } ]}})],1)]),_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title must"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.measurementColumn'))+": ")]),_c('default-multi-select',{staticClass:"tag-select input",attrs:{"values":[1, 2],"option-list":[
            { value: 1, name: _vm.$t('algorithm.temperature') },
            { value: 2, name: _vm.$t('algorithm.pressure') } ]}})],1)])]),_c('div',{staticClass:"table-board"},[_c('div',{staticClass:"board-title-row"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.modelParamSetting'))+" ")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"item-wrap"},[_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" CNN "+_vm._s(_vm.$t('algorithm.modelExtraLayers'))+": ")]),_c('input',{staticClass:"input",attrs:{"type":"text"},domProps:{"value":0}})]),_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.modelExtraLayers'))+": ")]),_c('input',{staticClass:"input",attrs:{"type":"text"},domProps:{"value":0}})])]),_c('div',{staticClass:"item-wrap"},[_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.learningSpeed'))+": ")]),_c('input',{staticClass:"input",attrs:{"type":"text"}})]),_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.trainningTimes'))+": ")]),_c('input',{staticClass:"input",attrs:{"type":"text"},domProps:{"value":20}})])]),_c('div',{staticClass:"item-wrap"},[_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.trainingBatchSize'))+": ")]),_c('input',{staticClass:"input",attrs:{"type":"text"},domProps:{"value":64}})]),_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.trainingAndVerificationDataRatio'))+": ")]),_c('input',{staticClass:"input",attrs:{"type":"text"},domProps:{"value":0.8}})])]),_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.standardizedMethod'))+": ")]),_c('default-select',{staticClass:"tag-select input",attrs:{"value":1,"option-list":[
            {value: 1, name: 'Range'} ]}})],1),_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.initParams'))+": ")]),_c('default-select',{staticClass:"tag-select input",attrs:{"value":1,"option-list":[
            {value: 1, name: 'Gaussian'} ]}})],1)])]),_c('div',{staticClass:"table-board"},[_c('div',{staticClass:"board-title-row"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.realTimeDataSetting'))+" ")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.targetDataSource'))+": ")]),_c('default-select',{staticClass:"tag-select input",attrs:{"option-list":[
            {value: 1, name: _vm.$t('algorithm.foxconnDemo')} ]}})],1),_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.targetDataFrame'))+": ")]),_c('default-select',{staticClass:"tag-select input",attrs:{"option-list":[
            {value: 1, name: 'TemperatureData'},
            {value: 2, name: 'molding'} ]}})],1),_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.executionCycle'))+": ")]),_c('default-select',{staticClass:"tag-select input",attrs:{"value":5,"option-list":[
            { value: 1, name: _vm.$tc('algorithm.second', 1) },
            { value: 2, name: _vm.$tc('algorithm.second', 15 ) },
            { value: 3, name: _vm.$tc('algorithm.second', 30) },
            { value: 4, name: _vm.$tc('algorithm.minuteLong', 1) },
            { value: 5, name: _vm.$tc('algorithm.minuteLong', 10) },
            { value: 6, name: _vm.$tc('algorithm.minuteLong', 15) },
            { value: 7, name: _vm.$tc('algorithm.minuteLong', 30) },
            { value: 8, name: _vm.$tc('algorithm.minuteLong', 40) },
            { value: 9, name: _vm.$tc('algorithm.minuteLong', 45) },
            { value: 10, name: _vm.$tc('algorithm.hourLong', 1) } ]}})],1)])]),_c('div',{staticClass:"footer"},[_c('button',{staticClass:"btn btn-outline",attrs:{"type":"button"},on:{"click":_vm.back}},[_vm._v(" "+_vm._s(_vm.$t('button.cancel'))+" ")]),_c('button',{staticClass:"btn btn-default",attrs:{"type":"button"},on:{"click":_vm.back}},[_c('span',[_vm._v(_vm._s(_vm.$t('button.confirm')))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }